export const GET_TEXT_REQUEST = "GET_TEXT_REQUEST"
export const GET_TEXT_SUCCESS = "GET_TEXT_SUCCESS"
export const GET_TEXT_FAIL = "GET_TEXT_FAIL"
export const GET_TEXT_RESET = "GET_TEXT_RESET"

export const TEXT_LIST_REQUEST = "TEXT_LIST_REQUEST"
export const TEXT_LIST_SUCCESS = "TEXT_LIST_SUCCESS"
export const TEXT_LIST_FAIL = "TEXT_LIST_FAIL"
export const TEXT_LIST_RESET = "TEXT_LIST_RESET"

export const TEXT_CREATE_REQUEST = "TEXT_CREATE_REQUEST"
export const TEXT_CREATE_SUCCESS = "TEXT_CREATE_SUCCESS"
export const TEXT_CREATE_FAIL = "TEXT_CREATE_FAIL"
export const TEXT_CREATE_RESET = "TEXT_CREATE_RESET"

export const TEXT_UPDATE_REQUEST = "TEXT_UPDATE_REQUEST"
export const TEXT_UPDATE_SUCCESS = "TEXT_UPDATE_SUCCESS"
export const TEXT_UPDATE_FAIL = "TEXT_UPDATE_FAIL"
export const TEXT_UPDATE_RESET = "TEXT_UPDATE_RESET"

export const TEXT_DELETE_REQUEST = "TEXT_DELETE_REQUEST"
export const TEXT_DELETE_SUCCESS = "TEXT_DELETE_SUCCESS"
export const TEXT_DELETE_FAIL = "TEXT_DELETE_FAIL"
