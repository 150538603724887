export const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST"
export const CART_ADD_ITEM_SUCCESS = "CART_ADD_ITEM_SUCCESS"
export const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL"

export const GET_CART_REQUEST = "GET_CART_REQUEST"
export const GET_CART_SUCCESS = "GET_CART_SUCCESS"
export const GET_CART_FAIL = "GET_CART_FAIL"
export const GET_CART_RESET = "GET_CART_RESET"

export const CART_REMOVE_ITEM_REQUEST = "CART_REMOVE_ITEM_REQUEST"
export const CART_REMOVE_ITEM_SUCCESS = "CART_REMOVE_ITEM_SUCCESS"
export const CART_REMOVE_ITEM_FAIL = "CART_REMOVE_ITEM_FAIL"

export const START_CHECKOUT_REQUEST = "START_CHECKOUT_REQUEST"
export const START_CHECKOUT_SUCCESS = "START_CHECKOUT_SUCCESS"
export const START_CHECKOUT_FAIL = "START_CHECKOUT_FAIL"
export const START_CHECKOUT_RESET = "START_CHECKOUT_RESET"

export const CART_LOCAL_ADD_ITEM = "CART_LOCAL_ADD_ITEM"
export const CART_LOCAL_REMOVE_ITEM = "CART_LOCAL_REMOVE_ITEM"

export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS"
export const CART_RESET_SHIPPING_ADDRESS = "CART_RESET_SHIPPING_ADDRESS"

// export const CART_CLEAR_REQUEST = "CART_CLEAR_REQUEST"
// export const CART_CLEAR_SUCCESS = "CART_CLEAR_SUCCESS"
// export const CART_CLEAR_FAIL = "CART_CLEAR_FAIL"

//
//
//
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM"
export const CART_UPDATE_ITEM = "CART_UPDATE_ITEM"

export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD"
export const CART_SAVE_START_CHECKOUT = "CART_SAVE_START_CHECKOUT"

export const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS"
export const CART_CLEAN_ITEMS = "CART_CLEAN_ITEMS"
export const CART_CLEAN_ALL = "CART_CLEAN_ALL"

export const CART_CHECK_ITEMS_REQUEST = "CART_CHECK_ITEMS_REQUEST"
export const CART_CHECK_ITEMS_SUCCESS = "CART_CHECK_ITEMS_SUCCESS"
export const CART_CHECK_ITEMS_FAIL = "CART_CHECK_ITEMS_FAIL"

export const CART_CLEAN_HOLDS_REQUEST = "CART_CLEAN_HOLDS_REQUEST"
export const CART_CLEAN_HOLDS_SUCCESS = "CART_CLEAN_HOLDS_SUCCESS"
export const CART_CLEAN_HOLDS_FAIL = "CART_CLEAN_HOLDS_FAIL"
